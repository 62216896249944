import React from 'react'
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from 'gatsby'

const PersonalInfoContainer = styled.div`
  @media (min-width: 1000px) {
    flex: 0.5;
  }
`

const H3 = styled.h3`
  text-transform: uppercase;
  margin-bottom: 20px;
  @media (max-width: 468px) {
    margin-bottom: 0px;
  }
`

const MainInformation = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 468px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Ul = styled.ul``
const Li = styled.li`
  margin: 15px 0;
  font-weight: 250;
`

const Span = styled.span`
  font-weight: 600;
  @media (min-width: 468px) {
    display: block;
  }
  @media (min-width: 1000px) {
    display: inline;
  }
`
const A = styled.a`
  width: fit-content;
  background: ${props => props.color};
  display: block;
  color: white;
  padding: 15px 25px;
  margin: 20px auto;
  border-radius: 35px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:hover {
    opacity: 0.7;
  }
`


const PersonalInfos = ({ color }) => {
  
  const dataToDownload = useStaticQuery(graphql`
    query fileQuery {
      file(extension: {eq: "rar"}) {
        relativePath
        publicURL
      }
    }

  `)

  console.log(dataToDownload)
    return (
      <PersonalInfoContainer>
        <H3>Personal Infos</H3>
        <MainInformation>
          <Ul>
            <Li>
              First Name: <Span>Ayush</Span>
            </Li>
            <Li>
              Last Name: <Span>Mainali</Span>
            </Li>
            <Li>
              Age: <Span>21</Span>
            </Li>
            <Li>
              Nationality: <Span>Nepali</Span>
            </Li>
            <Li>
              Freelance: <Span>Availiable</Span>
            </Li>
          </Ul>
          <ul>
            <Li>
              Address: <Span>Kathmandu, Nepal</Span>
            </Li>
            <Li>
              Phone: <Span>+977 9811335594</Span>
            </Li>
            <Li>
              Email: <Span>ayushmainali11@gmail.com</Span>
            </Li>
            <Li>
              Github: <Span>AyushMainali11</Span>
            </Li>
            <Li>
              Languages: <Span>Nepali, English, Hindi</Span>
            </Li>
          </ul>
        </MainInformation>
        {/* Download File Starts */}
        <A
          color = {color}
          href={dataToDownload.file.publicURL} download>
          Download CV
        </A>
        {/* Download File Ends */}
      </PersonalInfoContainer>
    )
}

export default PersonalInfos
