import React from 'react'
import styled from "@emotion/styled"

const BoxContainer = styled.div`
  padding: 20px 10px;
  border: 1px solid #4c4242;
  border-radius: 3px;
`

const H2 = styled.h2`
  color: ${props => props.color};
  font-weight: 1000;
  font-size: 40px;
  margin-left: 20px;
  &::after {
    content: "+";
  }
`

const H4 = styled.h4`
  text-transform: uppercase;
  font-weight: 400;
`


const Box = ({ number, text, color }) => {
  return (
    <BoxContainer>
      <H2 color = {color}>{number}</H2>
      <H4>{text}</H4>
    </BoxContainer>
  )
}

export default Box
