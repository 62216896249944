import React from "react"
import { Progress } from "react-sweet-progress"
import { Grid } from "@material-ui/core"
const Circle = ({ color, skill, percent }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <Progress
        type="circle"
        percent={percent}
        width={120}
        strokeWidth={8}
        status="active"
        theme={{ active: { color, trailColor: "#112222" } }}
      />
      <div style={{ marginTop: "15px", textTransform: "uppercase" }}>
        {skill}
      </div>
    </Grid>
  )
}

const skillLists = [
  { skill: "HTML", percent: 93 },
  { skill: "CSS", percent: 90 },
  { skill: "Javascript", percent: 80 },
  { skill: "Wordpress", percent: 65 },
  { skill: "React", percent: 90 },
  { skill: "NextJS", percent: 90 },
  { skill: "TypeScript", percent: 92 },
  { skill: "Gatsby", percent: 80 },
]

const Circles = ({ color }) => {
  return (
    <div style={{ marginBottom: "50px" }}>
      <h3
        style={{
          textTransform: "uppercase",
          textAlign: "center",
          marginBottom: "60px",
          fontSize: "30px",
        }}
      >
        My Skills
      </h3>
      <Grid container spacing={4}>
        {skillLists.map(({ skill, percent }, i) => (
          <Circle skill={skill} percent={percent} color={color} key={i} />
        ))}
      </Grid>
    </div>
  )
}

export default Circles
