import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle"
import PageTransition from "gatsby-plugin-page-transitions"
import { useStateValue } from "../contextProvider/StateProvider"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import Image from "gatsby-image"
import Circles from "../components/Circles"
import PersonalInfos from "../components/PersonalInfos"
import Box from "../components/Box"

const AboutContainer = styled.div``

const ImageContainer = styled.div`
  width: 230px;
  height: 230px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  border: ${props => `3px solid ${props.color}`};
  margin-bottom: 15px;
  @media (min-width: 468px) {
    display: none;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (min-width: 468px) {
    text-align: left;
  }
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 150px;
  gap: 30px;
  @media (min-width: 1000px) {
    margin-left: 20px;
    flex: 0.5;
    max-width: 500px;
  }
`

const Line = styled.div`
  margin: 80px auto;
  width: 300px;
  height: 1px;
  background: #4c424294;
`

const About = () => {
  const [{ color }] = useStateValue()
  const data = useStaticQuery(graphql`
    query AboutImage {
      imageSharp(fluid: { originalName: { eq: "ayush.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About" />
      <AboutContainer>
        <PageTransition
          transitionStyles={{
            entering: { opacity: "0.2" },
            entered: { opacity: "1" },
            exiting: { opacity: "0" },
          }}
          transitionTime={300}
        >
        <PageTitle frontTitle={"About"} coloredTitle={"ME"} color={color} />
        <ImageContainer>
          <Image fluid={data.imageSharp.fluid} alt="Ayush Mainali" />
        </ImageContainer>
        <InfoContainer>
          <PersonalInfos color={color} />
          <BoxContainer>
            <Box number={"3"} text={"Years Of Experience"} color={color} />
            <Box number={"10"} text={"Completed Projects"} color={color} />
            <Box number={"20"} text={"Happy Customers"} color={color} />
            <Box number={"3"} text={"Certifications"} color={color} />
          </BoxContainer>
        </InfoContainer>
        <Line />
        <Circles color={color} />
        <Line />
        </PageTransition>
      </AboutContainer>
    </Layout>
  )
}

export default About
